<form>
  <h4 mat-dialog-title>Are you sure</h4>
  <div mat-dialog-content>
    Please fill in your password to confirm
    <mat-form-field class="w-full p-t-m">
      <mat-label>Password</mat-label>
      <input type="password" [formControl]="password" matInput />
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="jc-end">
    <button mat-raised-button type="submit" color="primary" [disabled]="password.invalid" [jpLoadClick]="validatePassword()" [loadClickPreventDefault]="true">Submit</button>
  </div>
</form>
