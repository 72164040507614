<div class="columns" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="column" *ngFor="let column of columns; index as index;" cdkDrag>
    <div>
      <mat-checkbox [formControl]="column.active"></mat-checkbox>
      &nbsp;
      <mat-form-field class="mat-form-field-no-hint">
        <input matInput [placeholder]="'LABEL' | transloco" [formControl]="column.label">
      </mat-form-field>
    </div>
    <button type="button" tabindex="-1" mat-icon-button cdkDragHandle>
      <mat-icon>drag_indicator</mat-icon>
    </button>
  </div>
</div>
