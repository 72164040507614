/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    apiKey: 'AIzaSyDf9V_agTpnfN-Q6swQBeWiQiO5Uh6G60s',
    authDomain: 'jp-eds.firebaseapp.com',
    projectId: 'jp-eds',
    storageBucket: 'jp-eds.appspot.com',
    messagingSenderId: '20407881324',
    appId: '1:20407881324:web:eecea902b813cb7b47d249',
    measurementId: 'G-51YRLK6D0S'
  }
};
