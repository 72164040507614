import {NavigationItemWithActive} from '../app/shared/interfaces/navigation-item-with-active.interface';

/**
 * Configuration that is consistent across environments
 */
export const STATIC_CONFIG = {
  displayName: 'EDS',
  elements: {

    /**
     * This is removed from the components selector
     * when registering it as an element
     */
    componentPrefix: 'jms-',

    /**
     * This is added as the suffix instead
     * 
     * @example
     * A component with the selector jms-table
     * is registered as an element with the selector
     * jms-e-table
     */
    selectorPrefix: 'jms-e-',
  },
  login: {
    email: true,
    google: true,
    facebook: true
  },
  /**
   * We use this to redirect all unauthenticated users
   */
  loginRoute: ['/login'],
  /**
   * Used for redirecting all authenticated users
   * visiting pages for unauthenticated users
   */
  dashboardRoute: ['/dashboard'],
  navigation: {
    items: [
      {
        label: 'DASHBOARD',
        type: 'link',
        value: '/dashboard',
        authorized: ['admin'],
        icon: 'home'
      },
      {
        label: 'Inventory',
        type: 'expandable',
        icon: 'inventory',
        authorized: ['admin'],
        children: [
          {
            label: 'Products',
            type: 'link',
            value: '/m/inventory'
          },
          {
            label: 'Names',
            type: 'link',
            value: '/m/inventory-names'
          }
        ]
      } as any,
      {
        label: 'Crash Packs',
        type: 'link',
        value: '/m/crash-packs',
        authorized: ['admin'],
        icon: 'medical_services'
      },
      {
        label: 'Assignments',
        type: 'link',
        value: '/m/assignments',
        authorized: ['admin', 'medical', 'nurse'],
        icon: 'assignment'
      },
      {
        label: 'App Users',
        type: 'link',
        value: '/m/app-users',
        authorized: ['admin'],
        icon: 'person'
      },
      {
        label: 'Reports',
        type: 'link',
        value: '/reports',
        icon: 'bar_chart',
      },
      {
        label: 'USERS',
        type: 'link',
        value: '/m/users',
        authorized: ['admin'],
        icon: 'account_circle'
      },
      {
        label: 'Completed Assignments',
        type: 'link',
        value: '/m/completed-assignments',
        authorized: ['admin', 'nurse', 'medical', 'provider'],
        icon: 'task_alt'
      },
      {
        label: 'Documentation',
        type: 'expandable',
        icon: 'folder_open',
        authorized: ['admin'],
        children: [
          {
            label: 'SOP',
            type: 'link',
            value: '/m/documentation'
          },
          {
            label: 'Procedures',
            type: 'link',
            value: '/m/procedures'
          }
        ]
      } as any,
      {
        label: 'File Manager',
        type: 'link',
        value: '/storage',
        authorized: ['admin'],
        icon: 'folder'
      },
    ] as NavigationItemWithActive[]
  }
};
