import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {Auth, EmailAuthProvider, reauthenticateWithCredential} from '@angular/fire/auth';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {random} from '@jaspero/utils';
import {notify} from '@shared/utils/notify.operator';
import {Collections} from 'definitions';
import {from, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DbService} from '../../services/db/db.service';
import {StateService} from '../../services/state/state.service';

@Component({
  selector: 'jms-confirmation-password',
  templateUrl: './confirmation-password.component.html',
  styleUrls: ['./confirmation-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationPasswordComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private dbService: DbService,
    public state: StateService,
    private dialog: MatDialog,
    private auth: Auth
  ) {
  }

  password: FormControl;
  errorMap = {
    'auth/wrong-password': 'LOGIN.ERROR_MESSAGE',
    'auth/too-many-requests': 'LOGIN.TOO_MANY_ATTEMPTS_TRY_LATER',
    'auth/user-not-found': 'LOGIN.USER_NOT_FOUND'
  };

  ngOnInit() {
    this.password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  }

  validatePassword() {
    return () => from(
      reauthenticateWithCredential(
        this.auth.currentUser,
        EmailAuthProvider.credential(this.auth.currentUser.email, this.password.value)
      )
    )
      .pipe(
        catchError(e => {
          this.password.setValue('');
          return throwError(() => ({message: this.errorMap[e.code]}));
        }),
        switchMap(() => {
          switch (this.data.type) {
            case 'delete': {
              return this.dbService.removeDocument(this.data.moduleId, this.data.item.id);
            }
            case 'fillCrashPack': {
              const formData = this.data.form.getRawValue();

              const fillData = this.data.productsData.map((product: any) => ({
                id: product.id,
                assigned: product.assignedQuantity,
                productId: product.product.id,
                name: product.product.name,
                unit: product.product.unit,
                quantity: product.usedQuantity
              }));

              return this.dbService
                .setDocument(
                  [Collections.CrashPacks, this.data.id, 'fill-history'].join('/'),
                  'cfh-' + random.string(24),
                  {
                    createdOn: Date.now(),
                    user: this.state.user.id,
                    changes: fillData,
                    ...formData
                  }
                )
            }
            case 'fillInventory': {
              const data = this.data.form.getRawValue();

              return this.dbService
                .setDocument(
                  [Collections.Inventory, this.data.id, 'fill-history'].join('/'),
                  'ifh-' + random.string(24),
                  {
                    createdOn: Date.now(),
                    user: this.state.user.id,
                    beforeFillQuantity: this.data.available,
                    resultQuantity: this.data.available + data.quantity,
                    ...data
                  }
                );
            }
          }
        }),
        notify({
          success: (this.data.type === 'delete') ?
            'Document deleted successfully'
            : (this.data.type === 'fillCrashPack' || this.data.type === 'fillInventory')
              ? 'Fill completed successfully'
              : 'Operation done successfully'
        }),
        tap(() => {
          this.dialog.closeAll();
        })
      );
  }
}
